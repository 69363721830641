<template>
  <el-dialog title="多头借贷查询结果" width="800" :visible.sync="visible" :fullscreen="full" :close-on-click-modal="false" :close-on-press-escape="false" custom-class="v-dialog v-dialog--highLight risk-dialog" @open="computeHeight">
    <div>
      <tool :full-screen.sync="full" @download="download('多头借贷查询结果')"></tool>
      <h2 class="risk-dialogTitle">多头借贷查询结果</h2>
      <table class="risk-dialogTable">
        <colgroup>
          <col width="150"/>
          <col width="200"/>
          <col width="150"/>
          <col width="200"/>
        </colgroup>
        <tbody>
          <tr v-if="v.result.data.totalCounts">
            <td>严重违法</td>
            <td>{{v.result.data.totalCounts[0].blackCount}}</td>
            <td>信贷逾期</td>
            <td>{{v.result.data.totalCounts[1].blackCount}}</td>
          </tr>
          <tr>
            <td>法院涉诉</td>
            <td>{{v.result.data.totalCounts[2].blackCount}}</td>
            <td>潜在风险</td>
            <td>{{v.result.data.totalCounts[3].blackCount}}</td>
          </tr>
          <tr>
            <td>多头借贷</td>
            <td>{{v.result.data.totalCounts[4].blackCount}}</td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <table class="risk-dialogTable">
        <colgroup>
          <col width="100"/>
          <col width="100"/>
          <col width="100"/>
          <col width="100"/>
          <col width="100"/>
          <col width="100"/>
          <col width="100"/>
          <col width="100"/>
        </colgroup>
        <thead>
          <tr>
            <th>序号</th>
            <th>所属风险类型</th>
            <th>所属事件类型</th>
            <th>命中事件</th>
            <th>涉及金额(元)</th>
            <th>持续时长(天)</th>
            <th>发生日期</th>
            <th>信息来源</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in v.result.data.list" :key="index">
            <td>{{index+1}}</td>
            <td>{{getBlackName(item.blackRiskType)}}</td>
            <td>{{getBlackFactName(item.blackFactsType)}}</td>
            <td>{{item.blackFacts}}</td>
            <td>{{item.blackAmt}}</td>
            <td>{{item.blackDurationTime}}</td>
            <td>{{item.blackHappenDate}}</td>
            <td>{{item.blackPublishSource}}</td>
          </tr>
        </tbody>
      </table>
    </div>

  </el-dialog>
</template>

<script>
import mixin from './mixin'
export default {
  data() {
    return {
      visible: false,
      v: null
    }
  },
  mixins: [mixin],
  methods: {
    open(datas) {
      this.visible = true
      this.v = Object.assign(this.v, datas)
    },
    getBlackName(name) {
      return {
        'A': '严重违法',
        'B': '信贷逾期',
        'C': '法院涉诉',
        'D': '潜在风险',
        'E': '多头借贷'
      }[name]
    },
    getBlackFactName(name) {
      return {
        'A02': '名下公司税务重大违法',
        'B01': '失联',
        'B02': '贷款不良（逾期90天以上未还）',
        'B03': '短时逾期',
        'B04': '逾期',
        'C01': '失信被执行人',
        'C02': '被执行人',
        'C03': '裁判文书',
        'D01': '疑似催收风险',
        'D02': '名下公司存在违规行为（被税务局或者工商局公示）',
        'D03': '来自信贷高风险区域',
        'D04': '其他潜在风险',
        'E01': '7天内多头借贷',
        'E02': '1月内多头借贷',
        'E03': '3月内多头借贷',
        'E04': '疑似多头借贷',
      }[name]
    }
  },
  created() {
    // 初始化数据
    this.v = {}
  }
}
</script>
